$(function() {
  const nav = $(".header");
  const dropdowns = $(".main").find(".dropdown");

  // Display translucent navbar on dropdown hover or focus in
  dropdowns.on('mouseover focusin', function() {
    nav.addClass("translucent");
  });

  // Hide translucent navbar on dropdown hover or focus out
  dropdowns.on('mouseout focusout', function() {
    nav.removeClass("translucent");
  });

  // Display opaque navbar on scroll
  const query = window.matchMedia("(max-width: 600px) and (min-height: 560px)")
  $(window).on('scroll', function() {
    // Breakpoint for mobile view
    if(query.matches && (document.body.scrollTop > window.innerHeight * 0.67 ||
      document.documentElement.scrollTop > window.innerHeight * 0.67 )) {
      nav.addClass("opaque");
    } else if(document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
      nav.addClass("opaque");
    } else {
      nav.removeClass("opaque");
    }
  }).scroll();
});